.about {
    width: 100%;
    margin: 0 auto;
    height: 100vh;
}

.about h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    text-align: center;
}

.project-list {
    list-style-type: none;
    padding: 0;
}

.project-card {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1rem;
    scroll-snap-align: start;
    opacity: 0;
    animation: fadeIn 2s ease-in forwards;
    padding-left: 7%;
    padding-right: 5%;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.project-card img {
    width: 800px;
    height: auto;
}

.project-card .project-details {
    flex-grow: 1;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project-card .project-details h3 {
    margin: 0;
}

.project-card .project-details p {
    margin: 0.5rem 0;
}

