
.home-page {
    
        overflow: hidden; /* Restrict scrolling on larger screens */
      
    .text-zone {
        position: absolute;
        left: 12.5%;
        top: 42.5%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }


    h1 {
        color: rgb(101, 101, 101);
        font-size: 56px;
        line-height: 64px;
        margin: 0;
        font-family: 'JetBrains Mono', monospace;
        font-weight: 500;





        img {
            width: 32px;
            animation: rotateIn 1s linear both;
            animation-delay: 1.5s;
        }
    }
}

h2 {
    color: grey;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: 'Jet Brains Mono';
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
    margin-bottom: 50px;
}

h3 {
    color: grey;
    font-family: jetbrains mono;
}

.flat-button {
    color: rgb(50, 50, 50);
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid rgb(66, 66, 66);
    box-shadow:rgb(101, 101, 101);
    animation: fadeIn 3s 3.5s backwards;
    margin-top: 50px;
    white-space: nowrap;



    &:hover {
        background: yellow;
        color: #333;
    }
}

.stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;

}

.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 8s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid rgb(36, 35, 35);
        background: #efefef;
        box-shadow: -12px -12px 24px #FFFFFF, 12px 12px 24px #B4C1D5;
        /*background: rgba(127, 164, 233, 0.4);
        background: #0f0c29;
        background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
        background: linear-gradient(to right, #24243e, #302b63, #0f0c29);*/
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightyellow;

    }

    .face1 {
        transform: translateZ(100px);
        color: #dd0031;
    }

    .face2 {
        transform: rotateY(90deg) translateZ(100px);
        color: #f06529;
    }

    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        color: #28a4d9;
    }

    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color: #5ed4f4;
    }

    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        color: #efe11d;
    }

    .face6 {
        transform: rotateX(-90deg) translateZ(100px);
        color: #ec4d28;
    }
}

@keyframes swing {
    0% { transform: translateX(0); }
    50% { transform: translateX(38px); }
    100% { transform: translateX(0); }
}

@keyframes spincube {

    from,
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }

    16% {
        transform: rotateY(-90deg) rotateZ(90deg);
    }

    33% {
        transform: rotateY(-90deg) rotateX(90deg);
    }

    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
    }

    66% {
        transform: rotateY(-270deg) rotateX(90deg);
    }

    83% {
        transform: rotateX(90deg);
    }
}

@media (max-width: 768px) {
    body {
        overflow-x: hidden; 
    }

    .home-page {
        .text-zone {
            position: relative;
            top: -100px;
            transform: none;
            width: 70%; 
            max-height: unset;
            padding: 0 12px;
            text-align: center; 

            h1 {
                font-size: 32px;
                margin-top: 38px; 
                line-height: 50px;
            }


            .button-container {
                text-align: center; 
            }
            h3{
                text-align: center; 
                margin: 7px auto;
            }
            h2{
                text-align: center; 
                margin: 0 auto;
            }
            .flat-button {
                display: inline-block; 
                margin: 0;
                margin-top: 30px; 


        }}

        .stage-cube-cont {
            width: 70%; 
            height: auto; 
            padding-top: 0;
            position: relative; 
            overflow: visible;
            margin-top: -20px; 
            display: flex;
            justify-content: center; 
            align-items: center; 
        }

        .cubespinner {
            margin-left: 6.8rem; 
            margin-right: auto;
        }
    }
}



