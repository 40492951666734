.nav-bar {
    background: #18181800;
    width: 120px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    opacity: 90%;
    
    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 8px auto;
            width: 128px;
            height: auto;

            &.sub-logo {
                width: 10px;
            }
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 22px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;
            h2 {
                margin-top: -30px; 
            }
            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: rgb(79, 182, 79);

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 14px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &::after {
                    content: 'Go';
                }
            }
        }

        a.about-link {
            &:after {
                content: 'My';
            }
        }

        a.contact-link {
            &:after {
                content: 'My';
            }
        }

        a.active {
            svg {
                color: rgb(0, 0, 0);
                text-decoration: underline;
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 15px;
                line-height: 16px;

                &:hover svg {
                    color: #ffff00
                }
            }
        }
    }


}
@media (max-width: 768px) { 
    .nav-bar { 
        width: 100%; 
        height: auto;
        position: fixed; 
        bottom: -40px; 
        top: auto; 
        background: #333;
        opacity: 1;
        z-index: 1000;


        nav {
            display: flex;
            justify-content: space-around; 
            background: #f0f0f0;
            padding-top: 14px
        }
    }
}