html {
  font-size: 100%;
}

h1 {
  font-family: 'JetBrains Mono', monospace;
}

body {
  margin: 0;
  font-family: 300 12px/1.4 'Jet Brains Mono', sans-serif;
  color: #444;
  background: #f0f0f0;
  overflow-y: auto; /* Enable vertical scrolling for mobile */
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


