@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&display=swap');

$primary-color: #ffd701;

@import 'animate.css';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input, textarea {
  font-family: 'Helvetica Neue';
}
