.contact-us {
    position: relative;
    line-height: 22px;
    font-weight: 600;
  }
  .contact-us,
  .email,
  .name {
    align-self: stretch;
  }
  .name {
    position: relative;
  }
  .email {
    border: 0;
    font-family: var(--font-roboto);
    font-size: var(--font-size-base);
    background-color: var(--color-whitesmoke-100);
    border-radius: var(--br-7xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-base) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
  }
  .email:focus {
    outline: 0;
  }
  .email1,
  .name-parent {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  .name-parent {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-7xs);
  }
  .email1 {
    border: 0;
    font-family: var(--font-roboto);
    font-size: var(--font-size-base);
    background-color: var(--color-whitesmoke-100);
    border-radius: var(--br-7xs);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-base) var(--padding-xs);
    align-items: center;
    outline: 0;
  }
  .optional {
    color: #ddd;
  }
  .frame-group {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-lg);
  }
  .email3,
  .frame-group,
  .message-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .email3 {
    border: 0;
    background-color: var(--color-whitesmoke-100);
    font-family: var(--font-roboto);
    font-size: var(--font-size-base);
    align-self: stretch;
    border-radius: var(--br-7xs);
    height: 243px;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: row;
    padding: var(--padding-base) var(--padding-xs);
    box-sizing: border-box;
    outline: 0;
  }
  .message-parent {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-7xs);
  }
  .button,
  .frame-parent {
    font-size: var(--font-size-lg);
    font-family: var(--font-roboto);
  }
  .frame-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-lg);
    color: var(--color-dimgray);
  }
  .button {
    position: relative;
    letter-spacing: 0.46px;
    color: var(--primary-contrast);
    text-align: center;
  }
  .search-flights-button {
    cursor: pointer;
    border: 0;
    padding: var(--padding-xs) 18px;
    background-color: #3d9ab8;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }
  .search-flights-button:hover {
    background-color: #1e5b6f;
  }
  .form {
    position: relative;
    border-radius: 12px;
    background-color: var(--primary-contrast);
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.03);
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    width: 742px;
    display: flex;
    flex-direction: column;
    padding: 28px 30.000001907348633px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 17px;
    text-align: left;
    font-size: 22px;
    color: #434343;
    font-family: var(--font-poppins);
  }
  @media screen and (max-width: 768px) {
    .contact-us {
      font-size: var(--font-size-lg);
    }
    .name {
      font-size: var(--font-size-sm);
    }
    .search-flights-button {
      margin-right: auto;
    }
  }
  @media screen and (max-width: 428px) {
    .frame-group,
    .message-parent {
      flex: unset;
      align-self: stretch;
    }
    .frame-parent {
      flex-direction: column;
    }
    .search-flights-button {
      width: 100% !important;
    }
  }
  
