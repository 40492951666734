// Default styles for mobile
.project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;


    .project-image img {
        width: 80%;
        height: auto;
    }

    .project-info {
        align-items: center;
    }
    h3{
        font-size: 36px;
        font-family: JetBrains Mono;
    }
    p{
        font-family: JetBrains Mono;
        margin-bottom: 3rem; // Adjusted for consistency
    }
}

// Styles for desktop view
@media (min-width: 768px) {
    .project-card {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .project-image, .project-info {
            flex: 1; 
            margin-right: 2rem; 
        }

        &:nth-child(odd) {
            flex-direction: row; 
        }
        &:nth-child(even) {
            flex-direction: row-reverse; 
        }
    }

    .project-image {
        flex: 1; 
        margin-right: 2rem;
}
    .project-image img {
        width: 100%; 
        height: auto;
    }
}

@media (max-width: 768px) { 
    .project-card {
        margin-bottom: 6rem;
    }

    .project-image {
        margin-bottom: -2.5rem;
    }
    p{
        margin-top: -1.5rem;
    }

    .flat-button {
        margin-bottom: 1rem;
    }
}